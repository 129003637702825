html{
  width: 100%;
  overflow-x: hidden;
  display:block;
}

@font-face {
  font-family: 'Lexend Exa';
  src:
    url('./fonts/lexendexa-variablefont_wght-webfont.woff2') format('woff2'),
    url('./fonts/lexendexa-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}