

html{
  width: 100%;
  overflow-x: hidden;
  display:block;
  scroll-behavior:smooth;
}

body{
  max-width:100%;
  background-color:#fafafa !important;
}

.overflow-hidden{
  overflow:hidden;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1.25s, transform 1s;
  -webkit-transition: opacity 1.25s, transform 1s;
  -moz-transition: opacity 1.25s, transform 1s;
  -ms-transition: opacity 1.25s, transform 1s;
  -o-transition: opacity 1.25s, transform 1s;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
}

.fade-in-section-fast {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity .5s, transform .75s;
  -webkit-transition: opacity .5s, transform .75s;
  -moz-transition: opacity .5s, transform .75s;
  -ms-transition: opacity .5s, transform .75s;
  -o-transition: opacity .5s, transform .75s;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
}

.fade-in-section-slow {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1.5s, transform 1.25s;
  -webkit-transition: opacity 1.5s, transform 1.25s;
  -moz-transition: opacity 1.5s, transform 1.25s;
  -ms-transition: opacity 1.5s, transform 1.25s;
  -o-transition: opacity 1.5s, transform 1.25s;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
}

.fade-in-left-fast {
  opacity: 0;
  transform: translateX(-300px);
  transition: opacity .5s, transform .75s;
  -webkit-transition: opacity .5s, transform .75s;
  -moz-transition: opacity .5s, transform .75s;
  -ms-transition: opacity .5s, transform .75s;
  -o-transition: opacity .5s, transform .75s;
  -webkit-transform: translateX(-300px);
  -moz-transform: translateX(-300px);
  -ms-transform: translateX(-300px);
  -o-transform: translateX(-300px);
}
.fade-in-right-fast {
  opacity: 0;
  transform: translateX(300px);
  transition: opacity .5s, transform .75s;
  -webkit-transition: opacity .5s, transform .75s;
  -moz-transition: opacity .5s, transform .75s;
  -ms-transition: opacity .5s, transform .75s;
  -o-transition: opacity .5s, transform .75s;
  -webkit-transform: translateX(300px);
  -moz-transform: translateX(300px);
  -ms-transform: translateX(300px);
  -o-transform: translateX(300px);
}
.fade-fast {
  opacity: 0;
  transition: opacity .5s,;
  -webkit-transition: opacity .5s,;
  -moz-transition: opacity .5s,;
  -ms-transition: opacity .5s,;
  -o-transition: opacity .5s,;
}

.fade-in-left-slow {
  opacity: 0;
  transform: translateX(-300px);
  transition: opacity 1s, transform 1.25s;
  -webkit-transition: opacity 1s, transform 1.25s;
  -moz-transition: opacity 1s, transform 1.25s;
  -ms-transition: opacity 1s, transform 1.25s;
  -o-transition: opacity 1s, transform 1.25s;
  -webkit-transform: translateX(-300px);
  -moz-transform: translateX(-300px);
  -ms-transform: translateX(-300px);
  -o-transform: translateX(-300px);
}
.fade-in-right-slow {
  opacity: 0;
  transform: translateX(300px);
  transition: opacity 1s, transform 1.25s;
  -webkit-transition: opacity 1s, transform 1.25s;
  -moz-transition: opacity 1s, transform 1.25s;
  -ms-transition: opacity 1s, transform 1.25s;
  -o-transition: opacity 1s, transform 1.25s;
  -webkit-transform: translateX(300px);
  -moz-transform: translateX(300px);
  -ms-transform: translateX(300px);
  -o-transform: translateX(300px);
}
.fade-slow {
  opacity: 0;
  transition: opacity 1s,;
  -webkit-transition: opacity 1s,;
  -moz-transition: opacity 1s,;
  -ms-transition: opacity 1s,;
  -o-transition: opacity 1s,;
}

.visible {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

.masonry-grid {
  display: flex;
  margin-left: -20px;
  width: auto;
}
.masonry-grid_column {
  padding-left: 20px;
  background-clip: padding-box;
}
.share-buttons button{
  min-width:50px;
  max-width:60px;
  transition: all .25s;
  -webkit-transition: all .25s;
  -moz-transition: all .25s;
  -ms-transition: all .25s;
  -o-transition: all .25s;
  border-radius:0px !important;
  -webkit-border-radius:0px !important;
  -moz-border-radius:0px !important;
  -ms-border-radius:0px !important;
  -o-border-radius:0px !important;
  float:none !important;
}
.share-buttons button:hover{
  scale: 1.1;
  z-index:2;
}
.share-buttons button svg{
  width: 100%;
  height: auto;
  margin-bottom: 1px;
  margin-right:1px;
  filter:grayscale(.55);
  -webkit-filter:grayscale(.55);
}

.share-buttons button:hover svg{
  filter:grayscale(.3);
  -webkit-filter:grayscale(.3);
 
}

.slider-container{
  margin-right: -10px;
  margin-left: -10px;
}


.slider-control-centerleft, .slider-control-centerright{
  height: 100%;
  background-color: rgb(237 237 237 / 0%);
  opacity: .5;
  transition: all .3s;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
}
.slider-control-centerleft:hover, .slider-control-centerright:hover{
  background-color: rgb(237 237 237 / 70%);
  opacity: 1;
}

.post-setHtml strong{
  font-weight:600;
}
.post-body p, .post-body li{
  font-size:large;
}