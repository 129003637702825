@-webkit-keyframes slideDown {
	to {
	  opacity: 1;
	  margin-top: 0px;
	}
  }
  
  @keyframes slideDown {
	to {
	  opacity: 1;
	  margin-top: 0px;
	}
  }
  
  .navBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	margin: 20px 15%;
	padding: 0 40px;
  }
  
  .topNavBar {
	z-index: 2;
	top: 0;
	position: absolute;
  }
  
  .navFixed {
	background-color: rgba(250, 250, 250, 0.75);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	-webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	-webkit-animation: slideDown 0.2s 0.2s ease-out forwards;
	        animation: slideDown 0.2s 0.2s ease-out forwards;
	opacity: 0;
	margin-top: -75px;
	position: fixed;
	height: 75px;
  }
  
  .navLink {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	        transform: perspective(1px) translateZ(0);
	position: relative;
	overflow: hidden;
  }
  
  .navLink::before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 0;
	bottom: 0;
	background: #3443b4;
	height: 4px;
	-webkit-transform: translateY(4px);
	    -ms-transform: translateY(4px);
	        transform: translateY(4px);
	-webkit-transition: -webkit-transform 0.3s ease-out;
	transition: -webkit-transform 0.3s ease-out;
	-o-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  }
  
  .navLink:hover::before {
	-webkit-transform: translateY(0);
	    -ms-transform: translateY(0);
	        transform: translateY(0);
  }
  
  .logo {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
  }
  
  .logo img {
	margin-right: 10px;
  }
  
  .logoHeader {
	font-size: 24px;
	font-weight: 600;
	font-family: 'Lexend Exa, sans-serif';
  }
  
  .mobileNavWrap {
	padding-bottom: 0;
	width: 100%;
	left: 0;
	font-weight: bold;
	text-align: center;
	-webkit-animation: slideDown 0.2s 0.2s ease-out forwards;
	        animation: slideDown 0.2s 0.2s ease-out forwards;
	opacity: 0;
  }
  
  .navOpen {
	min-height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: rgba(250, 250, 250, 0.75);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	-webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	-webkit-animation: slideDown 0.2s 0.2s ease-out forwards;
	        animation: slideDown 0.2s 0.2s ease-out forwards;
  }
  
  .navOpen .mobileNav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	font-size: 24px;
	border-radius: 0;
	min-height: calc(100vh - 75px);
  }
  
  .mobileNav a {
	border-radius: 0;
  }